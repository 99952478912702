import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./contact.module.scss"
import EmailIcon from "../assets/email.inline.svg"
import InstagramIcon from "../assets/ig.inline.svg"

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout page="contact">
    <SEO title="Contact" />
    <p className={style.about}>{data.site.siteMetadata.description}</p>
    <ul className={style.contact}>
      <li>
        <a href="mailto:hi@aparna-aji.com">
          <EmailIcon />
          hi@aparna-aji.com
        </a>
      </li>
      <li>
        <a href="https://instagram.com/aparna_aji">
          <InstagramIcon />
          aparna_aji
        </a>
      </li>
    </ul>
    <div className={style.copyright}>
      <p>
        All content Copyright © 2019 - {new Date().getFullYear()} Aparna Aji,
        unless otherwise indicated.
      </p>
      <p className="handwriting">
        Site design by Aparna Aji, implementation by{" "}
        <a href="https://mcardle.io">Leo McArdle</a>.
      </p>
    </div>
  </Layout>
)

export default IndexPage
